import { Link } from "gatsby"
import styled from "styled-components"
import React, { useState } from "react"
import InlineCluster from "@bedrock-layout/inline-cluster"
import useMediaQuery from "@bedrock-layout/use-media-query"
import PadBox from "@bedrock-layout/padbox"
import navburger from "../images/white-burger-icon.png"
import logo from "../images/white-and-orange-text.png"

const LogoWrapper = styled.div`
  width: 200px;
  height: auto;
  img {
    margin: 0;
  }
`
const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  z-index: 100;
`

const NavLink = styled(Link)`
  color: #ed7723;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 0.9rem;
`

const NavLinkWrapper = styled(InlineCluster)`
  padding-right: 1em;
  * {
    padding-right: 8px;
  }
`

const MobileNavBox = ({ navOpen }) => (
  <StyledMobileNav navOpen={navOpen}>
    <NavLink to="/about/">About</NavLink>
    <NavLink to="/portfolio/">Portfolio</NavLink>
    <NavLink to="/contact/">Contact</NavLink>
  </StyledMobileNav>
)

const StyledMobileNav = styled(PadBox)`
  min-width: 125px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: #333;
  position: absolute;
  top: 70px;
  right: 0;
  z-index: 1;
  transition: 300ms;
  opacity: ${props => (props.navOpen ? 1 : 0)};
  transition-timing-function: ease-out;
  * {
    padding-bottom: 1em;
  }
`

const Header = () => {
  const smallScreen = useMediaQuery("(max-width:900px")
  const largeScreen = useMediaQuery("(min-width:901px")
  const [navOpen, setNavOpen] = useState(false)
  return (
    <>
      <header
        style={{
          background: `#333`,
          width: "100%",
        }}
      >
        <PadBox
          padding={["sm", "xl"]}
          style={{
            display: "flex",
            justifyContent: "space-between",
            maxWidth: "100vw",
          }}
        >
          <HeaderWrapper>
            <Link to="/">
              <LogoWrapper>
                <img src={logo} width="175" />
              </LogoWrapper>
            </Link>
            {largeScreen && (
              <NavLinkWrapper gutter="lg">
                <NavLink to="/about/">About</NavLink>
                <NavLink to="/portfolio/">Portfolio</NavLink>
                <NavLink to="/contact/">Contact</NavLink>
              </NavLinkWrapper>
            )}
            {smallScreen && (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                  }}
                  onClick={() => {
                    setNavOpen(!navOpen)
                  }}
                  onBlur={() => setNavOpen(false)}
                >
                  <img
                    src={navburger}
                    width="30"
                    alt="navigation menu icon"
                    style={{ margin: 0 }}
                  />
                </div>
              </>
            )}
          </HeaderWrapper>
          {smallScreen && <MobileNavBox navOpen={navOpen} />}
        </PadBox>
      </header>
    </>
  )
}

export default Header
