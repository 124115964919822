/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Header from "./header"
import styled from "styled-components"
import "./layout.css"
import AppBoundary from "@bedrock-layout/appboundary"

const Footer = styled.footer`
  bottom: 0;
  background: #333;
  height: 60px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Layout = ({ children }) => {
  return (
    <>
      <AppBoundary>
        <Header />
        <div
          style={{
            minHeight: `100vh`,
          }}
        >
          <main>{children}</main>
        </div>
        <Footer>
          Arik Parker © {new Date().getFullYear()}
          {` `}
        </Footer>
      </AppBoundary>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
